export const environment = {
	production: false,
	slugBackend: "api",
	urlBackEndLoginIpt: "https://authorization-proveedores-dev.ipt.pe",
	urlBackEndOpenCloseEvents: "https://eventos-campos-dev.ipt.pe",
	urlBackEndAlarms: "https://alarmas-eventos.ipt.pe",
	wsurlBackEndOpenCloseEvents: "wss://eventos-campos-dev.ipt.pe",
	urlClientApp: "https://proveedores-dev.ipt.pe",
	AZURE_CLIENT_ID: "60c4656b-b71f-4bad-b26d-c8fc72c95f98",
	AZURE_AUTHORITY:
    "https://login.microsoftonline.com/internetparatodospe.onmicrosoft.com",
};

/**
 * [1]: Se define de esa forma para que se pueda reutilizar en el azure.ts

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.